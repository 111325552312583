import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Fade, Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Twitter from '@material-ui/icons/Twitter';
import YouTube from '@material-ui/icons/YouTube';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as Discord } from '../assets/icons/discord.svg';
import ReactGA from 'react-ga';
import Sponsors from './Sponsors';

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'auto',
        maxHeight: '60vh'
    },
    [theme.breakpoints.down('md')]: {
        aboutContainer: {
            width: '100%',
            textAlign: 'center'
        },
        leftMe: {
            textAlign: 'left'
        }
    },
    [theme.breakpoints.up('lg')]: {
        aboutContainer: {
            width: '100%',
            textAlign: 'left'
        },
        centerMe: {
            textAlign: 'center'
        }
    },
    twitterContainer: {
        width: '100%',
        margin: theme.spacing(3),
        textAlign: 'left'
    },
    paper: {
        textAlign: 'center',
        padding: '6px 16px',
        margin: 0
    },
    dateLabel: {
        color: 'rgb(108,108,108)'
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main
    },
    timelineAvatar: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5)
    }
}));

export default function TeamAbout(props) {
    const classes = useStyles();

    function handleInteraction(event, inputLabel) {
        ReactGA.event({
            category: 'Interaction',
            action: 'Link Click',
            label: inputLabel
        });
    }

    const { currentView } = props;
    return (
        <Fade in={currentView === 0} mountOnEnter unmountOnExit>
            <Grid container alignItems="stretch" justifyContent="space-evenly" spacing={3} display="flex">
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={classes.aboutContainer}>
                        <Typography variant="h4" color="textPrimary" className={classes.centerMe}>
                            seVen
                        </Typography>
                        <Typography variant="subtitle1" color="primary" className={classes.centerMe}>
                            Esports & Content Creation Organization
                            <br />
                        </Typography>
                        <div style={{ textAlign: 'center' }}>
                            <IconButton
                                href="https://twitter.com/teamviiofficial"
                                target="_blank"
                                onClick={(event) => handleInteraction(event, 'Twitter')}
                            >
                                <Twitter fontSize="large" />
                            </IconButton>
                            <IconButton
                                href="https://www.youtube.com/channel/UCMW7G1ymvnB6O9krDHVtY6A"
                                target="_blank"
                                onClick={(event) => handleInteraction(event, 'YouTube')}
                            >
                                <YouTube fontSize="large" />
                            </IconButton>
                            <IconButton
                                href="https://discord.gg/vii"
                                target="_blank"
                                onClick={(event) => handleInteraction(event, 'Discord')}
                            >
                                <SvgIcon fontSize="large">
                                    <Discord />
                                </SvgIcon>
                            </IconButton>
                        </div>
                        <Typography style={{ margin: '16px' }} variant="h6" color="textSecondary">
                            We are VII, an esports and content organization.
                            <br />
                            Founded in 2017 as one of North America&apos;s elite Battlefield teams, we are a growing family of top-end
                            talent, hungry to compete and grow.
                            <br />
                            <br />
                            Follow our{' '}
                            <Link color="primary" href="https://twitter.com/teamviiofficial">
                                Twitter
                            </Link>{' '}
                            for news & updates on all things seVen.
                            <br />
                            Subscribe to our{' '}
                            <Link color="primary" href="https://www.youtube.com/channel/UCMW7G1ymvnB6O9krDHVtY6A">
                                channel
                            </Link>{' '}
                            for highlights.
                            <br />
                            Our content creators livestream their gameplay near-daily, be sure to check them out!
                            <br />
                            <br />
                            Membership via invitation only.
                            <br />
                            For official inquiries, you can reach us by emailing{' '}
                            <Link color="primary" href="mailto:info@vii.gg">
                                info@vii.gg
                            </Link>
                            .
                        </Typography>
                        <Sponsors pageType="about" />
                        <br />
                        <Typography style={{ textAlign: 'center' }} variant="subtitle1" color="textSecondary">
                            vii.gg{' '}
                            <Link color="primary" href="">
                                1.4.1
                            </Link>
                        </Typography>
                        <Typography style={{ textAlign: 'center' }} variant="subtitle2" color="textSecondary">
                            Created for <b>seVen</b>, with love by{' '}
                            <Link
                                color="primary"
                                href="https://twitter.com/DeltaraTV"
                                onClick={(event) => handleInteraction(event, 'Twitter_Deltara (About)')}
                            >
                                Deltara
                            </Link>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Fade>
    );
}
