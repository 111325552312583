import React, { useState } from 'react';
import logoBg from './assets/images/vii_logo_full.png';
import logoFg from './assets/images/vii_logo_512.png';
// import logoFgInitial from './assets/images/vii_logo_512.png';
import './App.css';
import TeamRoster from './components/TeamRoster';
import TeamAbout from './components/TeamAbout';
import TeamTimeline from './components/TeamTimeline';
import NavigationTabs from './components/NavigationTabs';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ReactGA from 'react-ga';
import AnimatedLogo from './components/animation/AnimatedLogo';

const TRACKING_ID = 'UA-185711980-2';
ReactGA.initialize(TRACKING_ID);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflowX: 'hidden'
    },
    navContainer: {
        position: 'relative',
        justifyContent: 'center',
        color: theme.palette.text.secondary
    },
    [theme.breakpoints.down('md')]: {
        contentContainer: {
            backgroundColor: 'theme.palette.background.paper',
            width: '100%',
            height: '80%'
        }
    },
    [theme.breakpoints.up('lg')]: {
        contentContainer: {
            backgroundColor: 'theme.palette.background.paper',
            width: '90%',
            maxWidth: '1600px',
            height: '80%',
            paddingLeft: '40px',
            paddingRight: '40px'
        }
    }
}));

export default function App() {
    const [currentView, setCurrentView] = useState(1);
    const [showInit, setShowInit] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const classes = useStyles();

    return (
        <div className="App">
            <img src={logoBg} className="App-logo-bg" alt="seVen-logo-bg" />

            <img src={logoFg} onLoad={() => setLoaded(true)} style={{ display: 'none' }} alt="seven-logo-initial-alt" />

            <AnimatedLogo style={loaded ? {} : { display: 'none' }} className="seVen-logo-initial" alt="seVen-logo-initial" />

            <Typography style={loaded ? {} : { display: 'none' }} className="header-initial" variant="h4" color="textSecondary">
                seVen
            </Typography>
            <Typography className="sub-initial" variant="subtitle1" color="primary" style={loaded ? {} : { display: 'none' }}>
                Esports & Content Creation Organization
            </Typography>

            <Fade in style={{ transitionDelay: '3200ms' }}>
                <img src={logoFg} className="seVen-logo-dormant" alt="seVen-logo" />
            </Fade>

            <Fade in style={{ transitionDelay: `3200ms` }}>
                <div className={classes.navContainer}>
                    <NavigationTabs setView={setCurrentView} />
                </div>
            </Fade>

            <Fade in style={{ transitionDelay: `3400ms` }}>
                <div className={classes.contentContainer}>
                    {currentView === 1 ? <TeamTimeline currentView={currentView} /> : <div />}
                    {currentView === 2 ? <TeamRoster currentView={currentView} /> : <div />}
                    {currentView === 0 ? <TeamAbout currentView={currentView} /> : <div />}
                </div>
            </Fade>
        </div>
    );
}
