import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import amd from '../assets/images/amd.png';
import sapphire from '../assets/images/sapphire.png';
import paraflex from '../assets/images/paraflex.png';
import skypad from '../assets/images/skypad.png';
import { Link } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    sub: {
        width: '300px'
    },
    paraflex: {
        width: '300px'
    },
    skypad: {
        width: '300px'
    },
    amd: {
        width: '300px'
    },
    sapphire: {
        width: '300px'
    }
}));

export default function Sponsors(props) {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(true);

    const fadeTime = 2000;

    function handleInteraction(event, inputLabel) {
        ReactGA.event({
            category: 'Interaction',
            action: 'Link Click',
            label: inputLabel
        });
        if (inputLabel === 'Paraflex') {
            window.open('https://bit.ly/3mCsr9c', '_blank');
        } else if (inputLabel === 'Skypad') {
            window.open('https://bit.ly/3AxMtFg', '_blank');
        } else if (inputLabel === 'AMD') {
            window.open('https://amd.com/', '_blank');
        } else if (inputLabel === 'Sapphire') {
            window.open('https://www.sapphiretech.com/en', '_blank');
        }
    }

    const sponsors = [
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <img
            style={{ cursor: 'pointer', paddingTop: '8px' }}
            onClick={(event) => handleInteraction(event, 'AMD')}
            src={amd}
            className={classes.amd}
            alt="amd-sponsor"
        />,
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <img
            style={{ cursor: 'pointer', paddingTop: '8px' }}
            onClick={(event) => handleInteraction(event, 'Sapphire')}
            src={sapphire}
            className={classes.sapphire}
            alt="sapphire-sponsor"
        />,
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <img
            style={{ cursor: 'pointer', paddingTop: '8px' }}
            onClick={(event) => handleInteraction(event, 'Skypad')}
            src={skypad}
            className={classes.skypad}
            alt="skypad-sponsor"
        />,
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <img
            style={{ cursor: 'pointer', paddingTop: '8px' }}
            onClick={(event) => handleInteraction(event, 'Paraflex')}
            src={paraflex}
            className={classes.paraflex}
            alt="paraflex-sponsor"
        />
    ];

    const sponsorsText = [
        <Typography variant="h6" color="textSecondary">
            Learn more at{' '}
            <Link color="primary" href="https://amd.com/" onClick={(event) => handleInteraction(event, 'AMD')}>
                AMD.com
            </Link>
            .
        </Typography>,
        <Typography variant="h6" color="textSecondary">
            Learn more at{' '}
            <Link color="primary" href="https://www.sapphiretech.com/en" onClick={(event) => handleInteraction(event, 'Sapphire')}>
                SapphireTech.com
            </Link>
            .
        </Typography>,
        <Typography variant="h6" color="textSecondary">
            Use code{' '}
            <Link color="primary" href="https://bit.ly/3AxMtFg" onClick={(event) => handleInteraction(event, 'Skypad')}>
                VII
            </Link>{' '}
            for 10% off Skypad purchases.
        </Typography>,
        <Typography variant="h6" color="textSecondary">
            Use code{' '}
            <Link color="primary" href="https://bit.ly/3mCsr9c" onClick={(event) => handleInteraction(event, 'Paraflex')}>
                VII
            </Link>{' '}
            for 15% off custom mouse cable purchases.
        </Typography>
    ];

    const [carouselData, setCarousel] = React.useState(sponsors[0]);
    const [carouselDataText, setCarouselText] = React.useState(sponsorsText[0]);

    useEffect(() => {
        let count = 0;
        // Interval Fade
        setInterval(() => {
            setChecked((prev) => !prev);
        }, fadeTime);
        // Interval Change Data
        setInterval(() => {
            if (count === sponsors.length - 1) {
                count = 0;
            } else count += 1;
            setCarousel(sponsors[count]);
            setCarouselText(sponsorsText[count]);
        }, fadeTime * 2);
    }, []);

    const { pageType } = props;
    return (
        <div className={classes.root} style={pageType === 'about' ? { marginLeft: '16px', marginRight: '16px' } : {}}>
            <Typography variant="subtitle1" color="primary">
                <br />
                POWERED BY
            </Typography>
            <Fade
                style={{ width: '300px' }}
                in={checked}
                timeout={{
                    enter: fadeTime - 1000,
                    exit: fadeTime
                }}
            >
                {carouselData}
            </Fade>
            <Fade
                in={checked}
                timeout={{
                    enter: fadeTime - 1000,
                    exit: fadeTime
                }}
            >
                {carouselDataText}
            </Fade>
        </div>
    );
}
