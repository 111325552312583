import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Hidden from '@material-ui/core/Hidden';
import origin from '../assets/images/origin_white.png';
import sens from '../assets/icons/sens.png';
import { ReactComponent as Twitch } from '../assets/icons/twitch.svg';
import { ReactComponent as Mouse } from '../assets/icons/mouse.svg';
import { ReactComponent as Fov } from '../assets/icons/fov.svg';
import { ReactComponent as Grip } from '../assets/icons/grip.svg';
import { ReactComponent as Pad } from '../assets/icons/pad.svg';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import YouTube from '@material-ui/icons/YouTube';
import Twitter from '@material-ui/icons/Twitter';
import RecentActivity from './RecentActivity';
import RosterNavigationTabs from './RosterNavigationTabs';
import Highlights from './Highlights';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactTimeAgo from 'react-time-ago';
import Chip from '@material-ui/core/Chip';
import FavoriteIcon from '@material-ui/icons/Favorite';

import MemberConstants from './team/MemberConstants';
import ReactGA from 'react-ga';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    [theme.breakpoints.down('sm')]: {
        root: {
            flexGrow: 1,
            height: '100%',
            backgroundColor: theme.palette.background.subPaperInit,
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    [theme.breakpoints.up('md')]: {
        root: {
            flexGrow: 1,
            height: '100%',
            backgroundColor: theme.palette.background.subPaperInit,
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'row'
        }
    },
    paper: {
        backgroundColor: theme.palette.background.subPaper,
        borderRadius: '8px',
        maxHeight: '80%',
        width: '100%'
    },
    paperBg: {
        position: 'fixed',
        backgroundColor: theme.palette.background.subPaper,
        borderRadius: '8px',
        height: '80%',
        width: '75%'
    },
    // Tabs
    tabsVertical: {
        borderRight: `1px solid ${theme.palette.divider}`,
        maxHeight: '70vh',
        overflow: 'hidden'
    },
    tabsHorizontal: {
        height: '20px'
    },
    tab: {
        minHeight: '10px',
        margin: 0,
        '&:hover': {
            color: theme.palette.text.primary,
            opacity: 1
        },
        '&$selected': {
            color: theme.palette.text.secondary,
            fontWeight: theme.typography.fontWeightMedium
        },
        '&:focus': {
            color: theme.palette.text.secondary
        }
    },
    selected: {},
    tabAvatar: {
        marginTop: theme.spacing(0.8),
        width: theme.spacing(4),
        height: theme.spacing(4)
    },

    iconLabelWrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        // textTransform: 'none',
        alignItems: 'center',
        textAlign: 'center',
        verticalAlign: 'middle'
    },
    // Tab Panel
    avatarLarge: {
        margin: theme.spacing(1),
        width: theme.spacing(7),
        height: theme.spacing(7)
    },
    originLogo: {
        margin: theme.spacing(0.5),
        marginLeft: 0,
        width: theme.spacing(2),
        height: theme.spacing(2),
        color: '#8b93aa'
    },
    originContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    memberHeader: {
        color: '#e3e3e3',
        marginTop: theme.spacing(1),
        fontSize: theme.spacing(3),
        textAlign: 'left',
        marginBottom: theme.spacing(-0.75)
    },
    memberSubheader: {
        color: '#8b93aa',
        fontSize: theme.spacing(2)
    },
    infoLogo: {
        marginRight: theme.spacing(0.5),
        width: theme.spacing(3.5),
        height: theme.spacing(3.5)
    },
    infoContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    infoLabel: {
        marginLeft: theme.spacing(0.5),
        color: theme.palette.primary.main,
        fontSize: theme.spacing(2.25),
        textTransform: 'uppercase'
    },
    [theme.breakpoints.down('md')]: {
        memberBioContainer: {
            color: '#e3e3e3',
            fontSize: theme.spacing(2),
            textAlign: 'left',
            verticalAlign: 'middle',
            alignContent: 'center',
            alignItems: 'center',
            minHeight: theme.spacing(6)
            // width: '100%',
        },
        memberBio: {
            color: '#e3e3e3',
            display: 'flex',
            margin: theme.spacing(1),
            fontSize: theme.spacing(2),
            textAlign: 'left',
            verticalAlign: 'middle',
            minHeight: theme.spacing(9),
            alignContent: 'center',
            alignItems: 'center'
        },
        infoContainer: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: theme.spacing(0.5),
            marginLeft: theme.spacing(0.25)
        }
    },
    [theme.breakpoints.up('lg')]: {
        memberBioContainer: {
            color: '#e3e3e3',
            fontSize: theme.spacing(2),
            textAlign: 'left',
            verticalAlign: 'middle',
            alignContent: 'center',
            alignItems: 'center',
            minHeight: theme.spacing(6)
            // width: '50%'
        },
        memberBio: {
            color: '#e3e3e3',
            display: 'flex',
            margin: theme.spacing(1),
            fontSize: theme.spacing(2),
            textAlign: 'left',
            verticalAlign: 'middle',
            minHeight: theme.spacing(9),
            alignContent: 'center',
            alignItems: 'center'
        },
        infoContainer: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            verticalAlign: 'middle',
            alignContent: 'center',
            alignItems: 'center',
            marginTop: theme.spacing(0.5),
            marginLeft: theme.spacing(0.25)
        }
    },
    belowBio: {
        color: '#e3e3e3',
        margin: theme.spacing(1),
        fontSize: theme.spacing(2),
        textAlign: 'left',
        justifyContent: 'flex-start'
    },
    highlightsHeader: {
        fontWeight: '700',
        textTransform: 'uppercase',
        textAlign: 'left'
    },
    activityHeader: {
        fontWeight: '700',
        textTransform: 'uppercase',
        textAlign: 'left'
    },
    refreshingHeader: {
        fontWeight: '400',
        color: '#8b93aa'
    },
    timerHeader: {
        fontWeight: '700',
        marginRight: -8
    },
    refreshingLogo: {
        margin: theme.spacing(0.5),
        marginLeft: 10.5,
        marginBottom: -4,
        marginRight: 0
    },
    refreshingWrapper: {
        margin: theme.spacing(0.5),
        textAlign: 'right',
        verticalAlign: 'middle',
        marginLeft: 0,
        marginBottom: 4,
        marginRight: 20
    },
    paperHighlight: {
        backgroundColor: theme.palette.background.subPaper,
        borderRadius: '8px',
        width: '100%',
        maxHeight: '44vh'
    },
    paperHighlightInit: {
        backgroundColor: theme.palette.background.subPaper,
        borderRadius: '8px',
        width: '100%',
        maxHeight: '36.5vh'
    },
    paperActivity: {
        backgroundColor: theme.palette.background.subPaper,
        borderRadius: '8px',
        width: '100%',
        maxHeight: '44vh'
    },
    loadingLogo: {
        margin: theme.spacing(18)
    },
    loadingLogoWrapper: {
        textAlign: 'center',
        verticalAlign: 'middle',
        justifyContent: 'space-around',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%'
    }
}));

export default function TeamRoster(props) {
    const classes = useStyles();

    function randomNumber(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    const [value, setValue] = useState(randomNumber(0, 15));
    const [refreshing, setRefreshing] = useState(false);
    // const [expanded, setExpanded] = useState(false);
    const [changedRoster, setChangedRoster] = useState(true);
    const [currentRosterView, setCurrentRosterView] = useState(1);
    const [memberConstants, setMemberConstants] = useState(MemberConstants);
    const [loadingVideo, setLoadingVideo] = useState(true);
    const [lastRefreshed, setLastRefreshed] = useState(Date.now() + 30000);
    const timerRef = useRef();

    const handleChange = (event, newValue) => {
        if (newValue >= memberConstants.length) {
            setValue(0);
        } else {
            setValue(newValue);
        }
        setLoadingVideo(true);
        let newMember = '';
        try {
            newMember = MemberConstants[newValue].name;
            // eslint-disable-next-line no-empty
        } catch {}
        ReactGA.event({
            category: 'Team Roster',
            action: 'Selected Member Profile',
            label: newMember
        });
    };

    function handleInteraction(event, inputLabel) {
        ReactGA.event({
            category: 'Team Roster',
            action: 'Link Click',
            label: inputLabel
        });
    }

    function handleOnReady() {
        setLoadingVideo(false);
    }

    const handleRefreshing = () => {
        clearTimeout(timerRef.current);
        setRefreshing(true);
        setLastRefreshed(Date.now() + 30000);

        timerRef.current = window.setTimeout(() => {
            setRefreshing(false);
        }, 1200);
    };

    useEffect(
        () => () => {
            clearTimeout(timerRef.current);
        },
        []
    );
    // <Fade in={value === index} key={`overall-fade-${index}`} timeout={500}>
    // <Fade in timeout={600}><Paper className={classes.paperBg}/></Fade>
    const { currentView } = props;
    return (
        <Fade in={currentView === 2} mountOnEnter unmountOnExit>
            <div>
                <RosterNavigationTabs
                    setRosterView={setCurrentRosterView}
                    setMemberConstants={setMemberConstants}
                    setMemberValue={setValue}
                    handleChange={handleChange}
                    memberValue={value}
                    setLoadingVideo={setLoadingVideo}
                    setChangedRoster={setChangedRoster}
                />
                <div className={classes.root}>
                    <Hidden smDown>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            scrollButtons="on"
                            value={value}
                            onChange={handleChange}
                            aria-label="seVen Roster"
                            className={classes.tabsVertical}
                        >
                            {memberConstants.map((member, index) => (
                                <Tab
                                    label={<>&nbsp;&nbsp;{member.name}</>}
                                    icon={<Avatar src={member.picture || ''} className={classes.tabAvatar} />}
                                    key={index}
                                    value={index}
                                    className={classes.tab}
                                    classes={{
                                        wrapper: classes.iconLabelWrapper,
                                        selected: classes.selected
                                    }}
                                    {...a11yProps(index)}
                                />
                            ))}
                        </Tabs>
                    </Hidden>
                    <Hidden mdUp>
                        <Tabs
                            orientation="horizontal"
                            variant="scrollable"
                            scrollButtons="on"
                            value={value}
                            onChange={handleChange}
                            aria-label="seVen Roster"
                            className={classes.tabsHorizontal}
                        >
                            {memberConstants.map((member, index) => (
                                <Tab label={member.name} key={index} className={classes.tab} {...a11yProps(index)} />
                            ))}
                        </Tabs>
                    </Hidden>
                    {memberConstants.map((member, index) => (
                        <TabPanel value={value} key={`tabPanel-${index}`} index={index} style={{ width: '100%' }}>
                            <Grid container display="flex" style={{ paddingBottom: '10px' }}>
                                <Fade in={value === index} key={`1-fade-${index}`}>
                                    <Grid container display="flex" style={{ paddingBottom: '10px', width: '60%' }}>
                                        <Grid item>
                                            <Avatar src={member.picture || ''} className={classes.avatarLarge} />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.memberHeader}>{member.name}</Typography>
                                            <Grid container className={classes.originContainer}>
                                                <img className={classes.originLogo} src={origin} alt="origin-icon" />
                                                <Typography className={classes.memberSubheader}>
                                                    {member.origin}&nbsp;&nbsp;&nbsp;
                                                </Typography>
                                                {member.council === true ? (
                                                    <Chip icon={<FavoriteIcon />} label="Council" color="primary" size="small" />
                                                ) : (
                                                    <div />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Fade>
                                <Fade in={value === index} key={`2-fade-${index}`}>
                                    <Grid container display="flex" style={{ paddingBottom: '0px', width: '40%' }}>
                                        <Grid container justifyContent="flex-end">
                                            <Grid item>
                                                {member.youtubeUrl === '' ? (
                                                    <div />
                                                ) : (
                                                    <IconButton
                                                        href={member.youtubeUrl}
                                                        target="_blank"
                                                        onClick={(event) => handleInteraction(event, `YouTube_${member.name}`)}
                                                    >
                                                        <YouTube fontSize="large" />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                            <Grid item>
                                                {member.twitchUrl === '' ? (
                                                    <div />
                                                ) : (
                                                    <IconButton
                                                        href={`https://www.twitch.tv/${member.twitchUrl}`}
                                                        target="_blank"
                                                        onClick={(event) => handleInteraction(event, `Twitch_${member.name}`)}
                                                    >
                                                        <SvgIcon fontSize="large">
                                                            <Twitch />
                                                        </SvgIcon>
                                                    </IconButton>
                                                )}
                                            </Grid>
                                            <Grid item>
                                                {member.twitterUrl === '' ? (
                                                    <div />
                                                ) : (
                                                    <IconButton
                                                        href={member.twitterUrl}
                                                        target="_blank"
                                                        onClick={(event) => handleInteraction(event, `Twitter_${member.name}`)}
                                                    >
                                                        <Twitter fontSize="large" />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Fade>
                            </Grid>
                            <Divider />
                            <Grid container display="flex" flexDirection="column" style={{ width: '100%' }}>
                                <Grid item>
                                    <Grid container spacing={3} className={classes.infoContainer}>
                                        <Grid item>
                                            <Grid container>
                                                <Mouse className={classes.infoLogo} />
                                                <Typography className={classes.infoLabel}>{member.mouse}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Grip className={classes.infoLogo} />
                                                <Typography className={classes.infoLabel}>{member.grip}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Pad className={classes.infoLogo} />
                                                <Typography className={classes.infoLabel}>{member.pad}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <img className={classes.infoLogo} src={sens} alt="sens-icon" />
                                                <Typography className={classes.infoLabel}>{`${member.sens}"`}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container>
                                                <Fov className={classes.infoLogo} />
                                                <Typography className={classes.infoLabel}>{`${member.fov}º`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.memberBioContainer}>
                                        <Fade in={value === index} key={`4-fade-${index}`}>
                                            <Typography className={classes.memberBio}>{member.bio}</Typography>
                                        </Fade>
                                    </div>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Hidden smDown>
                                <Grid
                                    container
                                    className={classes.belowBio}
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    display="flex"
                                >
                                    <Grid item xs={6}>
                                        <Typography className={classes.highlightsHeader} variant="subtitle1">
                                            Gameplay Highlights
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography className={classes.activityHeader} variant="subtitle1">
                                            Recent Events
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Fade in={value === index} key={`8-fade-${index}`}>
                                            <div className={classes.refreshingWrapper}>
                                                <Typography variant="caption" className={classes.refreshingHeader}>
                                                    Refreshing
                                                </Typography>
                                                {refreshing === false ? (
                                                    <Typography variant="caption" className={classes.timerHeader}>
                                                        &nbsp;in{' '}
                                                        <ReactTimeAgo future date={lastRefreshed} timeStyle="twitter" locale="en-US" />
                                                    </Typography>
                                                ) : (
                                                    <CircularProgress className={classes.refreshingLogo} size={16} />
                                                )}
                                            </div>
                                        </Fade>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Hidden mdUp>
                                <Grid
                                    container
                                    className={classes.belowBio}
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    display="flex"
                                >
                                    <Grid item xs={12}>
                                        <Typography className={classes.highlightsHeader} variant="subtitle1">
                                            Gameplay Highlights
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Grid container spacing={2} justifyContent="space-evenly" alignContent="flex-end" display="flex">
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    {loadingVideo === true ? (
                                        <div className={classes.loadingLogoWrapper}>
                                            <Paper className={classes.paperHighlightInit}>
                                                <CircularProgress className={classes.loadingLogo} size={64} />
                                            </Paper>
                                        </div>
                                    ) : (
                                        <div />
                                    )}
                                    <div
                                        style={
                                            loadingVideo === true
                                                ? { visibility: 'hidden', overflow: 'hidden' }
                                                : { visibility: 'visible', overflow: 'hidden' }
                                        }
                                    >
                                        <Fade in={loadingVideo === false} key={`10-fade-${index}`}>
                                            <Paper className={classes.paperHighlight}>
                                                {/* eslint-disable-next-line react/jsx-no-bind */}
                                                <Highlights handleOnReady={handleOnReady} highlightUrl={member.highlightUrl} />
                                            </Paper>
                                        </Fade>
                                    </div>
                                </Grid>
                                <Hidden mdUp>
                                    <Grid
                                        container
                                        className={classes.belowBio}
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                        display="flex"
                                    >
                                        <Grid item xs={7}>
                                            <Typography className={classes.activityHeader} variant="subtitle1">
                                                Recent Events
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Fade in={value === index} key={`8-fade-${index}`}>
                                                <div className={classes.refreshingWrapper}>
                                                    <Typography variant="caption" className={classes.refreshingHeader}>
                                                        Refreshing
                                                    </Typography>
                                                    {refreshing === false ? (
                                                        <Typography variant="caption" className={classes.timerHeader}>
                                                            &nbsp;in{' '}
                                                            <ReactTimeAgo future date={lastRefreshed} timeStyle="twitter" locale="en-US" />
                                                        </Typography>
                                                    ) : (
                                                        <CircularProgress className={classes.refreshingLogo} size={16} />
                                                    )}
                                                </div>
                                            </Fade>
                                        </Grid>
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Paper className={classes.paperActivity}>
                                        <RecentActivity
                                            memberName={member.origin}
                                            handleRefresh={handleRefreshing}
                                            isRefreshing={refreshing}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    ))}
                </div>
            </div>
        </Fade>
    );
}
