import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { ReactComponent as Discord } from '../assets/icons/discord.svg';
import Typography from '@material-ui/core/Typography';
import { Fade } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Twitter from '@material-ui/icons/Twitter';
import YouTube from '@material-ui/icons/YouTube';
import Avatar from '@material-ui/core/Avatar';
import MemberConstants from './team/MemberConstants';
import Hidden from '@material-ui/core/Hidden';
import TeamOnline from './TeamOnline';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import ReactGA from 'react-ga';
import Sponsors from './Sponsors';

import bfv from '../assets/images/bfv_logo_256.png';
import codMW from '../assets/images/mw_logo_256.png';
import apex from '../assets/images/apex_logo_256.png';
import halo from '../assets/images/halo_logo_256.png';
import vanguard from '../assets/images/vanguard_logo_256.png';
import waulfy from '../assets/images/members/waulfy.png';

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'auto',
        maxHeight: '70vh'
    },
    [theme.breakpoints.down('md')]: {
        aboutContainer: {
            maxWidth: '100%',
            margin: theme.spacing(3),
            textAlign: 'center'
        },
        onlineContainer: {
            width: '100%',
            textAlign: 'center'
        },
        inPaper: {
            textAlign: 'center',
            maxWidth: '75%',
            color: 'white'
        },
        paper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.paper,
            padding: '6px 16px',
            textTransform: 'none',
            textAlign: 'center',
            height: 'min-content',
            '&:hover': {
                backgroundColor: '#282433',
                color: theme.palette.secondary.main
            }
        },
        openIcon: {
            color: theme.palette.primary.main,
            verticalAlign: 'middle'
        }
    },
    [theme.breakpoints.up('lg')]: {
        aboutContainer: {
            width: '90%',
            margin: theme.spacing(4),
            textAlign: 'left'
        },
        onlineContainer: {
            width: '100%',
            textAlign: 'center'
        },
        inPaper: {
            textAlign: 'center',
            color: 'white',
            maxWidth: '82%'
        },
        paper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.paper,
            padding: '6px 16px',
            textTransform: 'none',
            textAlign: 'center',
            height: 'min-content',
            '&:hover': {
                backgroundColor: '#282433',
                color: theme.palette.secondary.main
            }
        },
        openIcon: {
            color: theme.palette.primary.main,
            verticalAlign: 'middle'
        }
    },
    dateLabel: {
        color: 'rgb(108,108,108)'
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main
    },
    timelineAvatar: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5)
    },
    paraflex: {
        width: '300px'
    },
    endIcon: {
        position: 'absolute',
        right: '1rem'
    },
    startIcon: {
        position: 'absolute',
        left: '1rem'
    },
    gameIcon: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: theme.palette.primary.main,
        verticalAlign: 'middle'
    }
}));

export default function TeamTimeline(props) {
    const [activity, setActivity] = useState(null);
    const [refreshing, setRefreshing] = useState(false);
    const DELAY_MS = 30000;

    const requestUrl = 'https://api.vii.gg/vii_activity';
    const options = { hour: 'numeric', minute: 'numeric', month: 'short', day: 'numeric' };

    useEffect(() => {
        function fetchActivity() {
            setRefreshing(true);
            fetch(requestUrl)
                .then((response) => response.json())
                .then((data) => setActivity(data.body));
            setRefreshing(false);
        }
        fetchActivity();
        const interval = setInterval(fetchActivity, DELAY_MS);
        return () => clearInterval(interval);
    }, [requestUrl]);

    function getMemberImage(inputName) {
        if (inputName === 'VII-Waulfy') {
            return waulfy;
        }
        for (let i = 0; i < MemberConstants.length; i += 1) {
            if (MemberConstants[i].origin === inputName) {
                return MemberConstants[i].picture;
            }
        }
        return null;
    }

    function handleInteraction(event, inputLabel) {
        ReactGA.event({
            category: 'Interaction',
            action: 'Link Click',
            label: inputLabel
        });
        if (inputLabel === 'Paraflex') {
            window.open('https://bit.ly/3mCsr9c', '_blank');
        }
    }

    function getGameIcon(inputStr) {
        switch (inputStr) {
            case 'Match Performance':
                return bfv;
            case 'Match Performance__MW':
                return codMW;
            case 'Match Performance__VG':
                return vanguard;
            case 'Match Performance__APEX':
                return apex;
            case 'Match Performance__HALO':
                return halo;
            default:
                return bfv;
        }
    }

    const classes = useStyles();

    const { currentView } = props;
    return (
        <Fade in={currentView === 1} mountOnEnter unmountOnExit>
            <Grid container alignItems="stretch" justifyContent="space-evenly" spacing={1} display="flex">
                <Grid item xs={12} sm={12} md={12} lg={5} xl={6}>
                    <div className={classes.aboutContainer}>
                        <Typography variant="h4" color="textPrimary">
                            seVen&nbsp;
                            <IconButton
                                href="https://twitter.com/teamviiofficial"
                                target="_blank"
                                onClick={(event) => handleInteraction(event, 'Twitter')}
                            >
                                <Twitter fontSize="medium" />
                            </IconButton>
                            <IconButton
                                href="https://www.youtube.com/channel/UCMW7G1ymvnB6O9krDHVtY6A"
                                target="_blank"
                                onClick={(event) => handleInteraction(event, 'YouTube')}
                            >
                                <YouTube fontSize="medium" />
                            </IconButton>
                            <IconButton
                                href="https://discord.gg/vii"
                                target="_blank"
                                onClick={(event) => handleInteraction(event, 'Discord')}
                            >
                                <SvgIcon fontSize="medium">
                                    <Discord />
                                </SvgIcon>
                            </IconButton>
                        </Typography>
                        <Typography variant="subtitle1" color="primary">
                            Esports & Content Creation Organization
                            <br />
                            <br />
                        </Typography>
                        <div className={classes.onlineContainer}>
                            <TeamOnline />
                        </div>
                        <Sponsors pageType="timeline" />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={7} xl={6}>
                    {refreshing || activity === null ? (
                        <Timeline className={classes.root} align="alternate" />
                    ) : (
                        <Timeline className={classes.root} align="alternate">
                            {activity.slice(0, 60).map((activityEvent, index) => (
                                <TimelineItem key={index}>
                                    <TimelineOppositeContent>
                                        <Hidden xsDown>
                                            <Typography className={classes.dateLabel} variant="body2">
                                                {new Date(activityEvent[2] * 1000).toLocaleString('en-US', options)}
                                            </Typography>
                                        </Hidden>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary" variant="outlined">
                                            <Avatar src={getMemberImage(activityEvent[1]) || ''} className={classes.timelineAvatar} />
                                        </TimelineDot>
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Button
                                            classes={{ endIcon: classes.endIcon, startIcon: classes.startIcon }}
                                            variant="contained"
                                            startIcon={<img className={classes.gameIcon} src={getGameIcon(activityEvent[3])} alt="" />}
                                            endIcon={<OpenInNewIcon className={classes.openIcon} />}
                                            href={activityEvent[6]}
                                            target="_blank"
                                            className={classes.paper}
                                        >
                                            <Typography variant="subtitle1" component="h1" className={classes.inPaper}>
                                                {activityEvent[5]}
                                            </Typography>
                                        </Button>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    )}
                </Grid>
            </Grid>
        </Fade>
    );
}
