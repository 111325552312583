import React from 'react';
import './AnimatedLogo.css';

export default function AnimatedLogo() {
    return (
        <svg className="seVen-logo-initial" data-name="Layer 1" viewBox="0 0 2000.92 1835.66">
            <defs>
                <linearGradient
                    id="uuid-8bd1e4aa-ede7-4dcb-8830-8db35839fc42"
                    x1="485.04"
                    y1="-123.88"
                    x2="1705.67"
                    y2="1096.75"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#9600ca" />
                    <stop offset=".2" stopColor="#9800be" />
                    <stop offset=".55" stopColor="#9e009e" />
                    <stop offset="1" stopColor="#a8006d" />
                </linearGradient>
                <linearGradient
                    id="uuid-d40703b8-7f1c-4cfd-a920-f7afe0f306f0"
                    x1="35.58"
                    y1="1113.85"
                    x2="1495.01"
                    y2="1113.85"
                    gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#ffdc92" />
                    <stop offset="1" stopColor="#ff8281" />
                </linearGradient>
            </defs>
            <path d="M993.38,1835.66c-265.89-256.97-531.55-513.99-797.4-770.98-62.93-65.4-115.25-140.83-148.4-225.68C-78.41,537.18,48.33,90.19,398.08,16.68c242.56-57.36,511.62,32.25,596.61,283.27C1076.79,103.98,1277-13.51,1487.87,3.71c315.46,12.81,530.86,345.42,511.89,642.15-6.9,132.84-55.67,265.49-151.61,359.92-282.27,279.36-568.21,554.94-854.78,829.88Z" />
            {/* Purple Gradient BG */}
            <path
                d="M1866.08,929.64c-27.8,37.75-63.2,68.78-95.73,102.29-6.61-137.58,1.62-279.21-1.35-417.94-39.83,34.04-77.3,73.05-116.34,108.77-17.91,16.93-35.91,33.77-53.58,50.95-1.66,1.62-2.51,3.46-2.6,5.74-.82,141.77,.69,284.22-2.25,425.65-33.03,32.26-65.87,64.71-99.21,96.64-5.23-140.53,1.57-284.22-2.38-425.21-68.74,66.28-137.21,132.68-206.03,198.89-2.62,2.48-5.32,4-6.46-.63-7.66-21.35-16.56-42.18-26.01-62.78-2.08-4.52,.79-6.37,3.89-8.29,52.94-33.95,115.11-74.6,122.55-142.6,6.99-61.88-6.21-126.72-42.57-177.72-28-37.97-29.24-86.37-44.89-129.48-22.83-64.78-80.24-108.59-137.53-141.98-77.17-45.66-173.91-56.27-259.64-30.63-4.74,1.51-8.47,1.07-12.63-1.67-44.96-31.66-110.58-41.86-152.9-.17-39.92,36.56-48.1,93.62-38.22,144.6,.54,2.96,.22,5.54-1.12,8.19-85.28,154.49-123.79,335.91,45.93,448.53,5.76,3.75,5.88,5.02,2.18,10.84-14.52,21.62-26.35,44.84-37.38,68.22-4.23,.33-6.5-2.92-9.06-5.3-41.64-40.05-83.39-79.97-125.18-119.88-159.45-151.93-318.58-304.21-478.1-456.07-10.81-10.12-21.03-20.88-32.27-30.56-1.7-1.46-3.75-2.92-3.61-5.66,24.12-103.81,69.61-205.46,146.32-281.11C297.53,64.08,440.28,31.49,574.33,37.75c127.79,5.1,256.93,60.51,330.86,168.08,22.95,32.46,41.03,67.89,54.7,105.13,8.91,30.53,52.84,35.77,66.76,7.16,25.28-59.28,61.8-113.84,108.65-158.3,111.87-109.58,282.43-142.41,432.3-108.21,191.25,43.13,321.08,218.1,375.38,397.92-18.74,21.52-42.6,38.61-63.04,58.72-5.46,5.33-14.15,9.72-13.55,18.48-1.48,134.21,4.04,268.99-.31,402.9Z"
                style={{ fill: 'url(#uuid-8bd1e4aa-ede7-4dcb-8830-8db35839fc42)' }}
            />
            {/* Light Gradient V */}
            <path
                d="M53.59,442.37c191.25,184.28,384.12,366.86,576.02,550.45,14.43,13.76,28.8,27.59,43.31,41.27,9.28,8.75,18.76,17.3,28.14,25.94,.89,1.67,.21,3.27-.3,4.83-7.39,22.62-10.8,46.02-13.28,69.57-.46,4.39-2.18,6.78-6.1,8.66-63.4,30.54-100.34,108.9-73.23,175.73,24.78,64.53,107.04,108.71,172.83,81.24,12.58-5.24,24-7.78,37.1-2.79,14.59,3.98,29.28,9.79,44.62,9.99,157.41,.89,314.97-2.07,472.32,1.07,16.74,.52,28.75-7.15,37.81-20.28,41.47-71-4.28-162.99-64.06-208.71-3.47-2.59-5.12-5.84-5.92-9.97-4.8-30.87-14.6-61.24-20.4-91.45,70.65-68.64,141.01-137.51,212.45-205.31,.1,143.07-.19,286.05,.11,429.11-166.56,161.84-334.05,322.68-501.49,483.6-188.47-181.89-376.56-364.22-564.97-546.2-60.44-58.44-120.89-116.89-181.34-175.32-40.04-38.61-78.31-79.91-108.39-126.86C44.29,792.73,11.58,609.89,53.59,442.37Z"
                style={{ fill: '#ffb392' }}
            >
                <animate attributeName="opacity" dur="1.0s" values="1.0;0.4;1.0" repeatCount="indefinite" begin="0.0" />
            </path>
            {/* I 1 */}
            <path
                d="M1594.22,1205.09c-1.09-142.19,.02-284.44,.64-426.65-.5-4.29,2.38-5.11,5.17-7.45,56.77-53.93,112.49-109.02,170.71-161.32,1.16,140.73-1.19,281.56-.4,422.26-57.79,58.49-116.51,116.51-176.12,173.16Z"
                style={{ fill: '#ff8181' }}
            >
                <animate attributeName="opacity" dur="1.0s" values="1.0;0.4;1.0" repeatCount="indefinite" begin="0.2" />
            </path>
            {/* I 2 */}
            <path
                d="M1866.08,929.64c-.4-134.98,.23-269.98-1.23-404.96-.02-3.22,.76-5.57,3.2-7.77,12.24-11.06,24.33-22.29,36.52-33.41,12.65-11.5,25.55-22.71,38.41-33.96,43.59,160.3,23.06,344.42-76.9,480.1Z"
                style={{ fill: '#ff8181' }}
            >
                <animate attributeName="opacity" dur="1.0s" values="1.0;0.4;1.0" repeatCount="indefinite" begin="0.4" />
            </path>
            {/* Panda */}
            <path d="M1282.46,1077.93c9.51,29.56,15.71,59.92,21.43,90.38,.82,4.35,2.47,7.55,6.28,10.5,39.28,30.41,64.86,69.67,75.89,118.33,7.71,35.32,6.12,77.41-22.49,103.23-7.19,6.26-15.65,9.25-25.04,9.22-90.25-.27-180.49-.73-270.74-.93-73.73-1.82-148.22,3.35-221.58-2.1-11.46-1.65-42.26-14.94-50.28-11.07-49.91,27.37-110.69,12.45-152.02-23.76-65.49-54.72-58-157.64,6.65-209.98,36.51-32.94,33.81-3.97,38.3-48.76,4.28-44.69,24.76-85.05,49.18-121.95,1.82-3.09,4.95-5.22,.37-7.81-12.47-8.03-24.54-16.83-35.31-27.05-71.75-63.61-96.38-139.84-82.18-233.99,5.61-69.4,41.05-130.02,69.68-191.91-5.33-49.51-7.3-98.63,27.13-139.53,40.53-53.92,116.8-48.45,167.16-11.44,3.29,2.22,6.07,2.34,9.58,1.17,14.2-4.71,28.82-7.54,43.57-9.92,77.44-11.98,159.85,2.83,226.19,45.14,112.74,69.9,126.6,110.33,156.66,233.94,3.42,12.17,11.16,22.24,18.08,32.62,32.62,46.23,46.2,103.33,44.68,159.45,.09,82.83-66.98,125.24-129.15,165.91,9.42,23.69,18.68,47.01,27.95,70.33Z" />
            <path
                d="M938.75,429.45c63.29-10.7,131.96-12.48,188.53,22.04,68.07,39.93,120.3,102.57,133.53,181.72,13.19,59.73,61.82,105.18,66.61,167.71-10.65-4.6-31.55-17.81-38.72-2.67-5.84,13.54-2.17,29.01,9.79,38.07,8.47,6.42,17.49,12.13,26.45,17.86,2.73,1.75,3.35,3.21,2.44,6.38-26.15,106.86-162.62,111.94-252.76,117.73-113.96,4.86-241.16,16.07-333.3-64.8-37.62-28.57-68.92-69.15-70.41-118.1-3.14-71.75,15.49-143.41,50.63-205.88,47.13,90.87,144.29,108.91,217.31,35.52,51.13-54.13,29.15-135.97-.1-195.56Z"
                style={{ fill: '#fefefe' }}
            />
            <path
                d="M1232.52,1050.17c14.93,37,36.12,72.43,39.88,112.56,.49,14.82,1.35,29.48,.72,44.33-55.41-30.7-58.01-28.27-120.15-21.21-72.82,5.99-141.94,29.26-205.95,64.05-2.6,1.33-5.52,2.39-8.39,2.78-6.83,1.24-11.82-9.12-5.23-12.65,41.46-24.39,88.26-39.44,133.38-55.8,44.62-13.95,92.41-13.61,138.27-20.26,0-39.58,1.94-81.11,27.46-113.8Z"
                style={{ fill: '#fefefe' }}
            />
            <path d="M1043.73,756.82c.87-19.93,6.37-38.46,14.05-56.49,14.1-33.1,36.72-58.73,67.66-76.91,12.89-7.57,26.67-12.81,42.06-11.95,12.12,.68,23,5.17,33.07,11.48,21.28,13.34,37.47,31.2,46.82,54.8,3.8,9.59,5.38,19.57,4.45,29.82-2.81,30.81-9.15,60.77-22.61,88.88-9.7,20.27-22.33,38.35-41.66,50.53-22.92,14.43-47.5,20.48-74.19,11.65-13.23-4.37-24.34-12.2-33.86-22.21-14.88-15.65-26.36-33.44-31.71-54.57-2.07-8.16-2.76-16.67-4.08-25.03Z" />
            <path
                d="M1142.31,747.94c1.84-23.97,10.83-41.62,28.9-53.96,12.43-8.49,26.59-7.21,38.19,2.56,16.57,13.96,23.19,38.36,16.05,58.88-3.23,9.28-6.87,18.34-12.71,26.34-11.19,15.35-26.02,18.38-42.25,8.56-10.59-6.4-19.72-14.38-24.19-26.26-2.19-5.8-3.04-12.11-4-16.12Z"
                style={{ fill: '#fefefe' }}
            />
        </svg>
    );
}
