import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import ReactGA from 'react-ga';
import MemberConstants from './team/MemberConstants';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap'
    },
    divider: {
        margin: theme.spacing(1, 0.5)
    }
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(1),
        justifyContent: 'stretch',
        border: 'none',
        maxHeight: '5vh',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius
        }
    }
}))(ToggleButtonGroup);

export default function NavigationTabs({ setView }) {
    const [value, setValue] = useState(1);
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        if (newValue === null) {
            return;
        }
        setView(newValue);
        setValue(newValue);
        let newTab = '';
        if (newValue === 0) {
            newTab = 'About';
            ReactGA.modalview('/about');
        } else if (newValue === 1) {
            newTab = 'Live Timeline';
            ReactGA.modalview('/timeline');
        } else if (newValue === 2) {
            newTab = 'Team Roster';
            ReactGA.modalview('/roster');
        }
        ReactGA.event({
            category: 'Navigation',
            action: 'Selected Tab',
            label: newTab
        });
    };

    return (
        <div>
            <StyledToggleButtonGroup size="large" value={value} exclusive fullWidth onChange={handleChange} aria-label="navigation">
                <ToggleButton value={1} aria-label="live timeline">
                    <Typography>Live Timeline</Typography>
                </ToggleButton>
                <ToggleButton value={2} aria-label="roster">
                    <Typography>Team Roster</Typography>
                </ToggleButton>
                <ToggleButton value={0} aria-label="about">
                    <Typography>About</Typography>
                </ToggleButton>
            </StyledToggleButtonGroup>
        </div>
    );
}
