import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%'
    }
}));

export default function Highlights(props) {
    const classes = useStyles();

    const { handleOnReady, highlightUrl } = props;
    return (
        <div className={classes.root}>
            <ReactPlayer
                style={{ overflow: 'hidden', maskImage: 'radial-gradient(circle, white 100%, black 100%)', borderRadius: '10px' }}
                width="100%"
                onReady={handleOnReady}
                controls
                url={highlightUrl}
            />
        </div>
    );
}

Highlights.propTypes = {
    highlightUrl: PropTypes.string.isRequired
};
