import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import ReactGA from 'react-ga';
import MemberConstants from './team/MemberConstants';
import Hidden from '@material-ui/core/Hidden';

import { ReactComponent as King } from '../assets/icons/king.svg';
import { ReactComponent as Earth } from '../assets/icons/earth.svg';
import { ReactComponent as Movie } from '../assets/icons/movie.svg';
import apex from '../assets/images/apex_logo_256.png';
import bf2042 from '../assets/images/bf2042_logo_256.png';
import vanguard from '../assets/images/vanguard_logo_256.png';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap'
    },
    divider: {
        margin: theme.spacing(1, 0.5)
    },
    rosterLogo: {
        marginRight: theme.spacing(0.5),
        width: theme.spacing(2.4),
        height: theme.spacing(2.4)
    },
    rosterMobile: {
        marginBottom: theme.spacing(0.1),
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    rosterSVG: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.1),
        width: theme.spacing(2),
        height: theme.spacing(2)
    }
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        justifyContent: 'normal',
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius
        }
    }
}))(ToggleButtonGroup);

export default function RosterNavigationTabs({ handleChange, memberValue, setLoadingVideo, setMemberConstants, setRosterView }) {
    const [thisValue, setThisValue] = useState(1);
    const classes = useStyles();

    const getMemberIndex = (inMembers, inName) => inMembers.findIndex((row) => row.name === inName);

    const handleRosterChange = (event, newValue) => {
        if (newValue === null) {
            return;
        }
        const previousMemberName = MemberConstants[memberValue].name;
        setThisValue(newValue);
        setLoadingVideo(true);
        setRosterView(newValue);

        // props.setMemberValue(0)
        let newTab = '';
        let rosterMembers = [];
        if (newValue === 1) {
            rosterMembers = MemberConstants.filter((item) => item.rosters.find((element) => element === 1));
            newTab = 'All';
        } else if (newValue === 2) {
            rosterMembers = MemberConstants.filter((item) => item.rosters.find((element) => element === 2));
            newTab = 'Leadership';
        } else if (newValue === 3) {
            rosterMembers = MemberConstants.filter((item) => item.rosters.find((element) => element === 3));
            newTab = 'Battlefield Roster';
        } else if (newValue === 4) {
            rosterMembers = MemberConstants.filter((item) => item.rosters.find((element) => element === 4));
            newTab = 'Apex Roster';
        } else if (newValue === 5) {
            rosterMembers = MemberConstants.filter((item) => item.rosters.find((element) => element === 5));
            newTab = 'CoD Roster';
        } else if (newValue === 6) {
            rosterMembers = MemberConstants.filter((item) => item.rosters.find((element) => element === 6));
            newTab = 'Creators';
        }

        setMemberConstants(rosterMembers);

        if (!rosterMembers.some((event) => event.name === previousMemberName)) {
            handleChange(null, 0);
        } else {
            handleChange(null, getMemberIndex(rosterMembers, previousMemberName));
        }
        ReactGA.event({
            category: 'Roster Navigation',
            action: 'Selected Tab',
            label: newTab
        });
    };

    return (
        <div>
            <Hidden smDown>
                <StyledToggleButtonGroup
                    size="small"
                    value={thisValue}
                    exclusive
                    onChange={handleRosterChange}
                    aria-label="roster-navigation"
                    style={{ top: '3vh', maxHeight: '4vh', width: '100%', display: 'flex', justifyContent: 'center' }}
                >
                    <ToggleButton value={1} aria-label="all">
                        <Earth className={classes.rosterSVG} />
                        <Typography variant="overline">All</Typography>
                    </ToggleButton>
                    <ToggleButton value={2} aria-label="leadership">
                        <King className={classes.rosterSVG} />
                        <Typography variant="overline">Leadership</Typography>
                    </ToggleButton>
                    <ToggleButton value={4} aria-label="apex-roster">
                        <img src={apex} className={classes.rosterLogo} alt="apex" />
                        <Typography variant="overline">Apex Legends</Typography>
                    </ToggleButton>
                    <ToggleButton value={3} aria-label="bf-roster">
                        <img src={bf2042} className={classes.rosterLogo} alt="bf2042" />
                        <Typography variant="overline">Battlefield</Typography>
                    </ToggleButton>
                    <ToggleButton value={5} aria-label="apex-roster">
                        <img src={vanguard} className={classes.rosterLogo} alt="cod" />
                        <Typography variant="overline">Call of Duty</Typography>
                    </ToggleButton>
                    <ToggleButton value={6} aria-label="content-creators">
                        <Movie className={classes.rosterSVG} />
                        <Typography variant="overline">Creators</Typography>
                    </ToggleButton>
                </StyledToggleButtonGroup>
            </Hidden>
            <Hidden mdUp>
                <StyledToggleButtonGroup
                    size="small"
                    value={thisValue}
                    exclusive
                    onChange={handleRosterChange}
                    aria-label="roster-navigation"
                    style={{ maxHeight: '4vh', width: '100%', display: 'block' }}
                >
                    <ToggleButton value={1} aria-label="leadership">
                        <Earth className={classes.rosterMobile} />
                    </ToggleButton>
                    <ToggleButton value={2} aria-label="leadership">
                        <King className={classes.rosterMobile} />
                    </ToggleButton>
                    <ToggleButton value={4} aria-label="apex-roster">
                        <img src={apex} className={classes.rosterMobile} alt="apex" />
                    </ToggleButton>
                    <ToggleButton value={3} aria-label="bf-roster">
                        <img src={bf2042} className={classes.rosterMobile} alt="bf2042" />
                    </ToggleButton>
                    <ToggleButton value={5} aria-label="cod-roster">
                        <img src={vanguard} className={classes.rosterMobile} alt="cod" />
                    </ToggleButton>
                    <ToggleButton value={6} aria-label="content-creator">
                        <Movie className={classes.rosterMobile} />
                    </ToggleButton>
                </StyledToggleButtonGroup>
            </Hidden>
        </div>
    );
}
