import adit from '../../assets/images/members/adit.png';
import bayuh from '../../assets/images/members/bayuh.png';
import catalyst from '../../assets/images/members/catalyst.png';
import chinoo from '../../assets/images/members/chinoo.png';
// import chrisP from '../../assets/images/members/chrisP.png'
import cincy from '../../assets/images/members/cincy.png';
import cuban from '../../assets/images/members/cuban.png';
import deltara from '../../assets/images/members/deltara.png';
import dovah from '../../assets/images/members/dovah.png';
import genuine from '../../assets/images/members/genuine.png';
import jordy from '../../assets/images/members/jordy.png';
import jayeH from '../../assets/images/members/jayeH.png';
import illest from '../../assets/images/members/illest.png';
import lowj4x from '../../assets/images/members/lowj4x.png';
import mike from '../../assets/images/members/mike.png';
import ninja from '../../assets/images/members/ninja.png';
import phaszr from '../../assets/images/members/phaszr.png';
import raspy from '../../assets/images/members/raspy.png';
import reaper from '../../assets/images/members/reaper.png';
import shadow from '../../assets/images/members/shadow.png';
import secret from '../../assets/images/members/secret.png';
import seth from '../../assets/images/members/seth.png';
import slothity from '../../assets/images/members/slothity.png';
// import vital from '../../assets/images/members/vital.png';
// import viv1d from '../../assets/images/members/viv1d.png'
import steve from '../../assets/images/members/steve.png';
import trigger from '../../assets/images/members/trigger.png';

// eslint-disable-next-line import/no-anonymous-default-export
// 1 == All, 2 == Leadership, 3 == Battlefield, 4 == Apex Legends, 5 == Call of Duty, 6 == Content Creators
export default [
    {
        name: 'Adit',
        origin: 'VII-Adit',
        picture: adit,
        bio: "I'm the Product Marketing Engineer for AMD Radeon and AMD Gaming. Lately, I've been playing Warzone, Rainbow 6, and F1 2021. Currently playing competitive Call of Duty for seVen.",
        highlightUrl: 'https://www.youtube.com/watch?v=mdRvmSmIPeE',
        youtubeUrl: 'https://www.youtube.com/channel/UCTLMSWyUQ9057J_1QUAD__g',
        twitterUrl: 'https://twitter.com/AMD_Adit',
        twitchUrl: 'adittt',
        council: false,
        mouse: 'Finalmouse Starlight',
        grip: 'Palm',
        sens: 6,
        fov: 120,
        pad: 'QcK Heavy: Black Ice Edition',
        rosters: [1, 5]
    },
    {
        name: 'Bayuh',
        origin: 'VII-Bayuh',
        picture: bayuh,
        bio: 'IRL he is an amateur freestyle BMX rider by day and Game Addict by night. He has been playing Call of Duty since the original Modern Warfare (2007), but plays most FPS games - also likes to play rpg and racing games. He takes pride in his aim. 500+ hours on Kovaaks, also rA ruby and Voltaic Diamond for benchmarks. Mouse and mousepad addict.  Lover of animals and car enthusiast.',
        highlightUrl: 'https://www.youtube.com/watch?v=L4dmEscCKB8',
        youtubeUrl: 'https://www.youtube.com/channel/UCW4AwRT52QtY2kGTVtf_HhA',
        twitterUrl: 'https://twitter.com/BayuhGG',
        twitchUrl: 'bayuh',
        council: false,
        mouse: 'Logitech G Pro X Superlight',
        grip: 'Fingertip',
        sens: 11.81,
        fov: 120,
        pad: 'Razer Stryder XXL',
        rosters: [1, 4, 6]
    },
    {
        name: 'Catalyst',
        origin: 'VII-Catalyst',
        picture: catalyst,
        bio: "Often considered to be 'too toxic for EU', Catalyst made a name for himself as a premiere console shitter making bad YouTube videos. Now having transcended to PC alpha chad, Catalyst has developed a lethal play-style that combines ratting on obscure angles, being the enemy B-squads punching bag and a dash of ape brained W-keying to save his KD.",
        highlightUrl: 'https://youtu.be/skuxokHNQSk',
        youtubeUrl: 'https://www.youtube.com/c/CatalystHD/',
        twitterUrl: 'https://twitter.com/catalyst_hd',
        twitchUrl: 'catalyst_hd',
        council: true,
        mouse: 'Razer Viper Mini',
        grip: 'Claw',
        sens: 11.5,
        fov: 92,
        pad: '?',
        rosters: [1, 2, 3, 6]
    },
    {
        name: 'Chinu',
        origin: 'VII-Chinu',
        picture: chinoo,
        bio: 'Discord emote god, PC peripheral addict, Doge coin holder, Elon Musk worshiper, and Kovaak believer.',
        highlightUrl: 'https://www.twitch.tv/videos/1122192136',
        youtubeUrl: '',
        twitterUrl: '',
        twitchUrl: 'chinuuuuuuuu',
        council: false,
        mouse: 'Logitech G Pro X Superlight',
        grip: 'Fingertip',
        sens: 8.5,
        fov: 103,
        pad: 'Skypad 2.0',
        rosters: [1, 3]
    },
    {
        name: 'Cincy',
        origin: 'VII-Cincy',
        picture: cincy,
        bio: 'Competitive in all aspects || Future Accountant',
        highlightUrl: 'https://www.twitch.tv/videos/957472299',
        youtubeUrl: '',
        twitterUrl: 'https://twitter.com/Cincy_v2',
        twitchUrl: 'cincy_v2',
        council: true,
        mouse: 'HyperX Pulsefire Haste',
        grip: 'Fingertip',
        sens: 10.38,
        fov: 95,
        pad: 'Fnatic Dash',
        rosters: [1, 2, 3, 6]
    },
    // {
    //     name: 'ChrisP',
    //     origin: 'VII-chrisP_ESF',
    //     picture: chrisP,
    //     bio: 'The oldest one in VII. It’s unclear whether it’s sheer determination to play competitive battlefield again or just plain masochism or just stupid. His wife would say the last.',
    //     highlightUrl: 'https://www.youtube.com/watch?v=V7kPLPe9cuo',
    //     youtubeUrl: 'https://www.youtube.com/c/ChrisPHD',
    //     twitterUrl: 'https://twitter.com/chrsprk26',
    //     twitchUrl: 'chr1spy',
    //     council: false,
    //     mouse: 'Razer Viper Ultimate',
    //     grip: 'Claw',
    //     sens: 9.8,
    //     fov: 100,
    //     pad: 'Fnatic Dash',
    //     rosters: [1],
    // },
    {
        name: 'Cuban',
        origin: 'VII-Cuban',
        picture: cuban,
        bio: "I play FPS games for fun, but that's missing as of late.",
        highlightUrl: 'https://www.twitch.tv/videos/1432812563',
        youtubeUrl: '',
        twitterUrl: '',
        twitchUrl: 'cubanhombre',
        council: false,
        mouse: 'Logitech G Pro X Superlight',
        grip: 'Palm/Claw Hybrid',
        sens: 6.0,
        fov: 89,
        pad: 'UtechSmart Ext-Large',
        rosters: [1, 3]
    },
    {
        name: 'Deltara',
        origin: 'VII-Deltara',
        picture: deltara,
        bio: 'Data scientist by day, stupidly aggressive ego-challer by night. Found on either Devastation, Al Marj, Marita, or Op Underground, and nowhere else. ZK-383 abuser. Will get a good clip once every two months while abusing flank routes, yet pretend they happen all the time.',
        highlightUrl: 'https://www.youtube.com/watch?v=XgTn1JZ50K4',
        youtubeUrl: 'https://www.youtube.com/c/Deltara',
        twitterUrl: 'https://www.twitter.com/DeltaraTV',
        twitchUrl: 'deltara_',
        council: true,
        mouse: 'Logitech G Pro X Superlight',
        grip: 'Fingertip',
        sens: 8,
        fov: 120,
        pad: 'Skypad 3.0',
        rosters: [1, 2, 5, 6]
    },
    {
        name: 'Dovah',
        origin: 'VII-Dovah',
        picture: dovah,
        bio: 'Ex-console main, now M&K enjoyer, who helped build and compete with Team Casual, the Battlefield team with the most wins in franchise history. Now helping manage and grow seVen to have the same, but better outcome.',
        highlightUrl: '',
        youtubeUrl: '',
        twitterUrl: '',
        twitchUrl: '',
        council: false,
        mouse: 'Logitech G402',
        grip: 'Claw',
        sens: 10,
        fov: 100,
        pad: 'Corsair MM800 Polaris',
        rosters: [1, 5]
    },
    {
        name: 'Genuine',
        origin: 'VII-Genuine',
        picture: genuine,
        bio: 'From CS:GO, to Rocket League, and now Battlefield, this PA farmer aims to excel at every competitive environment he is thrown into. Aim training enthusiast.',
        highlightUrl: 'https://www.youtube.com/watch?v=5LgLsFv6tUE',
        youtubeUrl: 'https://www.youtube.com/channel/UCpUAgg7exqM6CypnJhFO8ow',
        twitterUrl: '',
        twitchUrl: 'gaminghog763',
        council: false,
        mouse: 'HyperX Pulsefire Haste',
        grip: 'Fingertip',
        sens: 10,
        fov: 120,
        pad: 'Skypad 3.0',
        rosters: [1, 3]
    },
    {
        name: 'Illest',
        origin: 'VII-IllestVang',
        picture: illest,
        bio: "FPS enjoyer. Started on console, then on to MnK around 3 years ago, when I got my first PC. Just kept getting better from then on. I've always wanted to become a competitive player.",
        highlightUrl: 'https://streamable.com/w9tefz',
        youtubeUrl: '',
        twitterUrl: 'https://twitter.com/VangIllest',
        twitchUrl: 'illestvang_',
        council: false,
        mouse: 'Logitech G Pro X Superlight',
        grip: 'Fingertip/Claw',
        sens: 14.5,
        fov: 110,
        pad: 'VicTsing XXL',
        rosters: [1, 4]
    },
    {
        name: 'Jordy',
        origin: 'VII-Jordy',
        picture: jordy,
        bio: 'Ultra Console Pleb that resists his urge to switch to Mouse/PC.',
        highlightUrl: 'https://youtu.be/Lw13gRWMCL8',
        youtubeUrl: 'https://www.youtube.com/channel/UCxlWbEjUNau2wlszBeDB5XA',
        twitterUrl: 'https://twitter.com/Jordy_17_',
        twitchUrl: '',
        council: false,
        mouse: 'Logitech G Pro X Superlight',
        grip: 'Palm/Claw Hybrid',
        sens: 11.14,
        fov: 115,
        pad: 'Nobody Knows',
        rosters: [1, 3, 6]
    },
    {
        name: 'jayeH',
        origin: 'VII-jayeH',
        picture: jayeH,
        bio: 'Co-Founder, route man, lurker, and Spence\'s biggest hater. "Music first, basic needs second." ~Jaye, probably.',
        highlightUrl: 'https://www.youtube.com/watch?v=0VrcahjVm7E',
        youtubeUrl: 'https://www.youtube.com/channel/UCCFCJhUsH_sS35vD1B44lcg',
        twitterUrl: 'https://twitter.com/jayeHHH',
        twitchUrl: '',
        council: false,
        mouse: 'Zowie FK1',
        grip: 'Fingertip',
        sens: 12,
        fov: 90,
        pad: 'QcK Heavy',
        rosters: [1, 5]
    },
    // {
    //     name: 'Lafiamma',
    //     origin: 'VII-Lafiamma',
    //     picture: lafiamma,
    //     bio: 'Trotting around the globe doing string theory research and clicking heads. Washed up comp Warzone player, mouse accel enthusiast, unpaid KovaaK’s sales rep.',
    //     highlightUrl: 'https://www.twitch.tv/videos/739538212',
    //     youtubeUrl: '',
    //     twitterUrl: 'https://twitter.com/LafiammaTV',
    //     twitchUrl: 'lafiamma',
    //     council: false,
    //     mouse: 'Logitech G Pro X Superlight',
    //     grip: 'Fingertip',
    //     sens: 8,
    //     fov: 88,
    //     pad: 'Glorious XXL',
    // },
    {
        name: 'LowJ4X',
        origin: 'VII-LowJ4X',
        picture: lowj4x,
        bio: 'Former rainbow six siege professional player and then coach. Best braindead BF 2042 flying squirrel woman. Massive FPS addict.',
        highlightUrl: 'https://youtu.be/PUmlTWFihcs',
        youtubeUrl: 'https://www.youtube.com/channel/UCj9baZcFtkxoXZQIeGMzFvg',
        twitterUrl: 'https://twitter.com/UA_Hardy',
        twitchUrl: 'lowj4x',
        council: false,
        mouse: 'Zowie ZA-13',
        grip: 'Claw',
        sens: 12,
        fov: 95,
        pad: 'Vitesse Full Desk Mat',
        rosters: [1, 3]
    },
    {
        name: 'Mike',
        origin: 'VII-13551',
        picture: mike,
        bio: "An avid pole twitcher, Mike Numbers has taken many names, including founder, leader, and manager of the back-to-back champion team No Name Apes. Mike's passion, hard work, and dedication towards the Battlefield competitive scene is seldom rivaled as he strives towards making Battlefield a game for competitive FPS players.",
        highlightUrl: 'https://streamable.com/69rkk6',
        youtubeUrl: '',
        twitterUrl: 'https://twitter.com/Mike13551',
        twitchUrl: 'mikenumb3rs',
        council: false,
        mouse: 'HyperX Pulsefire Haste',
        grip: 'Fingertip',
        sens: 11.5,
        fov: 100,
        pad: 'Artisan Hien',
        rosters: [1, 3]
    },
    {
        name: 'Ninja',
        origin: 'VII-Ninjuh',
        picture: ninja,
        bio: 'CS:GO, Call of Duty, and Rust enjoyer. Now playing competitive Battlefield for seVen.',
        highlightUrl: '',
        youtubeUrl: 'https://www.youtube.com/channel/UC2WUWU1zbbCM8vS4lSpJNEQ',
        twitterUrl: '',
        twitchUrl: 'ngmninja',
        council: false,
        mouse: 'Razer Death Adder V2',
        grip: 'Palm/Claw Hybrid',
        sens: 11,
        fov: 100,
        pad: 'HyperX Fury S Pro XL',
        rosters: [1, 3]
    },
    // {
    //     name: 'Peyt1n',
    //     origin: 'VII-peyt1n',
    //     picture: peyt1n,
    //     bio: 'A 22-year-old Porsche fan.',
    //     highlightUrl: 'https://streamable.com/3bdl3j',
    //     youtubeUrl: 'https://www.youtube.com/channel/UCatqKnspmRRm1UEbuXBUw0g',
    //     twitterUrl: 'https://twitter.com/peyt1n',
    //     twitchUrl: 'peyt1nn',
    //     council: false,
    //     mouse: 'Logitech G Pro X Superlight',
    //     grip: '?',
    //     sens: 8.5,
    //     fov: 100,
    //     pad: '?',
    //     rosters: [1, 3],
    // },
    {
        name: 'PHASZR',
        origin: 'VII-PHASZR',
        picture: phaszr,
        bio: 'Former professional CoD4 player, IGL, and manager in CAL-M / CEVO-M / ESL 2008-2010 with team Zen Mechanics. CAL-M in CS 1.6 with team SHIFTSIX. 10+ years competitive LAN experience. Brand and Media relations manager, I am a "veteran locker room guy" for VII.',
        highlightUrl: 'https://www.youtube.com/watch?v=3Tdr-3OE780',
        youtubeUrl: 'https://www.youtube.com/channel/UCkP4MYuM6EZ1yEg7CShXWlg',
        twitterUrl: 'https://twitter.com/PHASZR',
        twitchUrl: '',
        council: false,
        mouse: 'Origin One X White',
        grip: 'Claw',
        sens: 8.5,
        fov: 95,
        pad: 'Skypad 2.0 XL',
        rosters: [1, 6]
    },
    {
        name: 'Raspy',
        origin: 'VII-Raspy',
        picture: raspy,
        bio: 'Started playing in a competitive setting during BF1 and further progressed my skills over multiple games, including Call of Duty and Apex Legends. I have transcended over the years, forming a ratty passive aggressive info spewing superstar.',
        highlightUrl: 'https://youtu.be/eGpLK-vLKgY',
        youtubeUrl: 'https://www.youtube.com/channel/UCH2aK4zdFblwalk67AdZuMg',
        twitterUrl: 'https://twitter.com/DNorth15',
        twitchUrl: 'raspithy',
        council: false,
        mouse: 'Logitech G Pro X Superlight',
        grip: 'Claw',
        sens: 11,
        fov: 89,
        pad: 'Skypad 2.0 XL',
        rosters: [1, 3, 5]
    },
    {
        name: 'Reaper',
        origin: 'Vll-Reaper',
        picture: reaper,
        bio: 'Known console trashcan.',
        highlightUrl: 'https://youtu.be/j8_WrnzGqW4',
        youtubeUrl: 'https://www.youtube.com/channel/UCr94hfuEpIM1OQTAs256MPQ',
        twitterUrl: '',
        twitchUrl: 'TheGreenReaper31',
        council: false,
        mouse: 'HyperX Pulsefire Haste',
        grip: 'Palm',
        sens: 7.8,
        fov: 90,
        pad: 'Mad Catz G.L.I.D.E.7',
        rosters: [1, 3, 5]
    },
    {
        name: 'Secret',
        origin: 'VII-Secret',
        picture: secret,
        bio: 'Coming from a small family farm, Secret has established himself as a world class malder and a top vehicle hater across multiple games and leagues. A devout follower of the Skey mindset, Secret continues to rat and actively avoid any and all fair fights.',
        highlightUrl: 'https://www.youtube.com/watch?v=z9vIFKyV3V4',
        youtubeUrl: 'https://www.youtube.com/channel/UCbO-GzaiAuoPcTUYwFCpxNA',
        twitterUrl: 'https://twitter.com/SSquirrel43',
        twitchUrl: 'secretsquirrel43',
        council: false,
        mouse: 'HyperX Pulsefire Haste',
        grip: 'Fingertip',
        sens: 11.6,
        fov: 100,
        pad: 'Skypad 2.0 XL',
        rosters: [1, 3, 6]
    },
    {
        name: 'Sethy',
        origin: 'VII-SethyP123',
        picture: seth,
        bio: 'RPG/Rock N Roll enjoyer. Recently started getting into FPS games in the last 2 years (Apex Legends/Escape from Tarkov). Previous Mordhau degenerate, 1v1 ranked D3. IRL dog dad, Apex grinder, Valk one-trick.',
        highlightUrl: 'https://streamable.com/k5cbeo',
        youtubeUrl: '',
        twitterUrl: 'https://twitter.com/SethyP_3',
        twitchUrl: '',
        council: false,
        mouse: 'Logitech G703 Lightspeed',
        grip: 'Palm/Fingertip',
        sens: 15,
        fov: 100,
        pad: 'XXL Amazon Desk Mat',
        rosters: [1, 4]
    },
    {
        name: 'Shadow',
        origin: 'VII-shadoww',
        picture: shadow,
        bio: 'Working to be a top aimer and go pro in the near future. I will stop at nothing!',
        highlightUrl: 'https://www.youtube.com/watch?v=vjJt8UcmxLw',
        youtubeUrl: 'https://www.youtube.com/channel/UCXVF-b8yQIbRg5UMbC09c6Q',
        twitterUrl: 'https://twitter.com/NotShadow___',
        twitchUrl: 'notshadow___',
        council: false,
        mouse: 'Logitech G Pro X Superlight',
        grip: 'Fingertip',
        sens: 9.5,
        fov: 110,
        pad: 'Artisan Hien Soft',
        rosters: [1, 3]
    },
    {
        name: 'Sloth',
        origin: 'VII-Sloth',
        picture: slothity,
        bio: 'Audio Boi',
        highlightUrl: 'https://streamable.com/24co32',
        youtubeUrl: '',
        twitterUrl: 'https://twitter.com/slothitygames',
        twitchUrl: 'slothity216',
        council: false,
        mouse: 'HyperX Pulsefire Haste',
        grip: 'Palm',
        sens: 15,
        fov: 100,
        pad: 'Aorus AMP500',
        rosters: [1, 6]
    },
    // {
    //     name: 'SpenceVn',
    //     origin: 'VII-SpenceVn',
    //     picture: spenceVn,
    //     bio: 'Co-Founder and avid uninstaller, Spence is jayeH\'s biggest hater. "I know it\'s not Christmas yet but Imma go ahead and wrap this one up boys", is his most famous quote.',
    //     highlightUrl: 'https://streamable.com/f37r7',
    //     youtubeUrl: 'https://www.youtube.com/user/SpencersDayOff',
    //     twitterUrl: '',
    //     twitchUrl: 'spencevn',
    //     council: true,
    //     mouse: 'Endgame XM1',
    //     grip: 'Palm/Claw Hybrid',
    //     sens: 14.5,
    //     fov: 87,
    //     pad: 'Artisan Hien',
    //     rosters: [1, 2],
    // },
    {
        name: 'Steve',
        origin: 'VII-BrotherSteve',
        picture: steve,
        bio: '28yrs old, 10 year U.S Army Combat Vet. Actively played the Battlefield Franchise since Battlefield 4 and Hardline.',
        highlightUrl: 'https://www.twitch.tv/videos/651220274',
        youtubeUrl: '',
        twitterUrl: 'https://twitter.com/YourBigBroSteve',
        twitchUrl: 'yourbigbrothersteve',
        council: false,
        mouse: 'N/A',
        grip: 'N/A',
        sens: '?',
        fov: '?',
        pad: 'N/A',
        rosters: [1, 6]
    },
    {
        name: 'Trigger',
        origin: 'VII-Trigger',
        picture: trigger,
        bio: "The self-proclaimed 'Sock Sleeve Aimer,' Trigger studies genetic engineering by day and clicks heads by night. Despite being new to the Battlefield scene, Trigger hopes to turn some heads with his years of training on 100+ ping, unparalleled spawn beacon flanks and meta weapon beams. Satisfies VII's nationality diversity quota as an Australian.",
        highlightUrl: 'https://youtu.be/FougElnN6gE',
        youtubeUrl: 'https://www.youtube.com/user/Rubix3421',
        twitterUrl: 'https://twitter.com/TriggerBF',
        twitchUrl: 'triggerbf',
        council: false,
        mouse: 'Pwnage Ultra Custom Ergo',
        grip: 'Palm/Claw Hybrid',
        sens: 13.8,
        fov: 100,
        pad: 'Artisan Zero Soft',
        rosters: [1, 6]
    }
    // {
    //     name: 'Vital',
    //     origin: 'VII-Vital',
    //     picture: vital,
    //     bio: 'Gamer Boi',
    //     highlightUrl: 'https://www.youtube.com/watch?v=HRKSWtwcqdg',
    //     youtubeUrl: 'https://www.youtube.com/channel/UC2h0IGTtqZhlQWi5p2Y6GQA',
    //     twitterUrl: 'https://twitter.com/Vital_XE',
    //     twitchUrl: 'vitalxe',
    //     council: false,
    //     mouse: 'Logitech G Pro X Superlight',
    //     grip: 'Palm',
    //     sens: 8,
    //     fov: 89,
    //     pad: 'Logitch G640',
    //     rosters: [1, 3]
    // }
    // {
    //     name: 'Vivid',
    //     origin: 'VII-vivid',
    //     picture: viv1d,
    //     bio: 'Started my Battlefield career playing in various Battlefield 2 online leagues such as CAL. Now a washed-up father of 2 trying to relive the glory days of Battlefield comp.',
    //     highlightUrl: 'https://www.youtube.com/watch?v=kwyjKZpMDNw',
    //     youtubeUrl: 'https://www.youtube.com/channel/UCI0iP6-I5FXAzrMo7G9ofaw',
    //     twitterUrl: 'https://twitter.com/viv1d',
    //     twitchUrl: 'viv1d',
    //     council: false,
    //     mouse: 'Starlight-12 Zeus Medium',
    //     grip: 'Palm/Claw Hybrid',
    //     sens: 12,
    //     fov: 84,
    //     pad: 'Artisan Hien',
    //     rosters: [1],
    // },
    // {
    //     name: 'DeltaraTest',
    //     origin: 'VII-Tester',
    //     picture: deltara,
    //     bio: 'Data scientist by day, stupidly aggressive ego-challer by night. Found on either Devastation, Al Marj, Marita, or Op Underground, and nowhere else. ZK-383 abuser. Will get a good clip once every two months while abusing flank routes, yet pretend they happen all the time.',
    //     highlightUrl: 'https://www.youtube.com/watch?v=XgTn1JZ50K4',
    //     youtubeUrl: 'https://www.youtube.com/c/Deltara',
    //     twitterUrl: 'https://www.twitter.com/DeltaraTV',
    //     twitchUrl: 'deltara_',
    //     council: true,
    //     mouse: 'Logitech G Pro X Superlight',
    //     grip: 'Fingertip',
    //     sens: 7,
    //     fov: 105,
    // }
];
