import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import purple from '@material-ui/core/colors/purple';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

const theme = createTheme({
    palette: {
        type: 'dark',
        primary: purple,
        secondary: purple,
        background: {
            paper: 'rgba(43,42,45,0.3)',
            subPaper: 'rgba(48,43,56,0.4)',
            subPaperInit: 'rgba(48,43,56,0)'
        }
    },
    typography: {
        fontWeight: '400',
        fontFamily: ['"Ubuntu"', '"Helvetica Neue"', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(',')
    }
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider>,
    document.getElementById('root')
);
