import React, { useEffect, useState } from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MemberConstants from './team/MemberConstants';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ArrowDropDownCircleRoundedIcon from '@material-ui/icons/ArrowDropDownCircleRounded';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ReactPlayer from 'react-player';
import ReactGA from 'react-ga';

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    }
}))(Badge);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {}
    },
    memberContainer: {
        minWidth: '10vw'
    },
    memberHeader: {
        color: '#e3e3e3',
        marginTop: theme.spacing(0.25),
        fontSize: theme.spacing(2),
        textAlign: 'left',
        textTransform: 'none'
    },
    memberAvatar: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5)
    },
    arrowIcon: {
        marginBottom: theme.spacing(-1),
        marginLeft: theme.spacing(2),
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: theme.palette.primary.main
    },
    [theme.breakpoints.down('md')]: {
        paper: { padding: '10px', marginTop: '10px', width: '100%', height: '39vh' },
        twitchEmbed: {
            width: '90vw',
            overflow: 'hidden'
        }
    },
    [theme.breakpoints.up('lg')]: {
        paper: { padding: '10px', marginTop: '10px', width: '100%', height: '39vh' },
        twitchEmbed: {
            width: '34vw',
            maxWidth: '700px',
            overflow: 'hidden'
        }
    }
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        justifyContent: 'stretch',
        border: 'none',
        maxHeight: '4vh',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius
        }
    }
}))(ToggleButtonGroup);

export default function TeamOnline(props) {
    const [value, setValue] = useState(0);
    const [channel, setChannel] = useState(null);
    const [online, setOnline] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const classes = useStyles();
    const DELAY_MS = 60000;

    function randomMember(input) {
        return input[Math.floor(Math.random() * input.length)];
    }

    const requestUrl = 'https://api.vii.gg/vii_online';

    useEffect(() => {
        function fetchOnline() {
            setRefreshing(true);
            fetch(requestUrl)
                .then((response) => response.json())
                .then((data) => setOnline(data.body));
            setRefreshing(false);
        }
        fetchOnline();
        const interval = setInterval(fetchOnline, DELAY_MS);
        return () => {
            clearInterval(interval);
        };
    }, [requestUrl]);

    const handleChange = (event, newValue) => {
        if (newValue == null) {
            return;
        }
        setValue(newValue);
    };

    function getMemberImage(inputName) {
        for (let i = 0; i < MemberConstants.length; i += 1) {
            if (MemberConstants[i].origin === inputName) {
                return MemberConstants[i].picture;
            }
        }
        return null;
    }

    function getMemberObject(inputName) {
        for (let i = 0; i < MemberConstants.length; i += 1) {
            if (MemberConstants[i].origin === inputName) {
                return MemberConstants[i];
            }
        }
        return null;
    }

    function handleInteraction(event, inputLabel) {
        ReactGA.event({
            category: 'Interaction',
            action: 'Select Online Member',
            label: inputLabel
        });
    }

    return (
        <div className={classes.root}>
            <Grid display="flex">
                {refreshing || online.length === 0 ? (
                    <div />
                ) : (
                    <div>
                        <Grid container spacing={2} display="flex">
                            <StyledToggleButtonGroup
                                size="large"
                                value={value}
                                exclusive
                                fullWidth
                                onChange={handleChange}
                                aria-label="navigation"
                            >
                                {online.map((onlineMember, index) => (
                                    <ToggleButton
                                        className={classes.memberContainer}
                                        key={index}
                                        value={index}
                                        onClick={(event) => handleInteraction(event, getMemberObject(onlineMember[0]).name)}
                                    >
                                        <Grid container display="flex">
                                            <Grid item xs={3}>
                                                <StyledBadge
                                                    overlap="circular"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }}
                                                    variant="dot"
                                                >
                                                    <Avatar
                                                        className={classes.memberAvatar}
                                                        alt="online"
                                                        src={getMemberImage(onlineMember[0]) || ''}
                                                    />
                                                </StyledBadge>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography className={classes.memberHeader}>
                                                    {getMemberObject(onlineMember[0]).name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                {value === index ? (
                                                    <ArrowDropDownCircleRoundedIcon className={classes.arrowIcon} />
                                                ) : (
                                                    <ArrowDropDownCircleOutlinedIcon className={classes.arrowIcon} />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </ToggleButton>
                                ))}
                            </StyledToggleButtonGroup>
                        </Grid>
                    </div>
                )}
                <div className={classes.twitchEmbed}>
                    <Paper className={classes.paper}>
                        {online.length === 0 ? (
                            <div>
                                <ReactPlayer
                                    playing
                                    controls
                                    muted
                                    style={{
                                        overflow: 'hidden',
                                        maskImage: 'radial-gradient(circle, white 100%, black 100%)',
                                        borderRadius: '4px'
                                    }}
                                    width="100%"
                                    url="https://www.youtube.com/watch?v=5lEkaTSPF_Q"
                                />
                            </div>
                        ) : (
                            <div>
                                <ReactPlayer
                                    playing
                                    style={{
                                        overflow: 'hidden',
                                        maskImage: 'radial-gradient(circle, white 100%, black 100%)',
                                        borderRadius: '4px'
                                    }}
                                    width="100%"
                                    url={`https://www.twitch.tv/${online[value][2]}`}
                                />
                            </div>
                        )}
                    </Paper>
                </div>
            </Grid>
        </div>
    );
}
