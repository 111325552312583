import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';

import bfv from '../assets/images/bfv_logo_256.png';
import codMW from '../assets/images/mw_logo_256.png';
import apex from '../assets/images/apex_logo_256.png';
import halo from '../assets/images/halo_logo_256.png';
import vanguard from '../assets/images/vanguard_logo_256.png';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: '36.5vh'
    },
    activityAvatarSkeleton: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },
    activityAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },
    dateLabel: {
        color: 'rgb(108,108,108)'
    },
    gameIcon: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: theme.palette.primary.main,
        verticalAlign: 'middle'
    }
}));

export default function RecentActivity(props) {
    const [activity, setActivity] = useState(null);
    const DELAY_MS = 30000;

    const { isRefreshing, memberName } = props;
    const requestUrl = `https://api.vii.gg/vii_activity?member=${memberName}`;
    const options = { hour: 'numeric', minute: 'numeric', month: 'short', day: 'numeric' };

    const { handleRefresh } = props;

    function getGameIcon(inputStr) {
        switch (inputStr) {
            case 'Match Performance':
                return bfv;
            case 'Match Performance__MW':
                return codMW;
            case 'Match Performance__VG':
                return vanguard;
            case 'Match Performance__APEX':
                return apex;
            case 'Match Performance__HALO':
                return halo;
            default:
                return bfv;
        }
    }

    useEffect(() => {
        function fetchActivity() {
            handleRefresh(true);
            fetch(requestUrl)
                .then((response) => response.json())
                .then((data) => setActivity(data.body));
            handleRefresh(false);
        }
        fetchActivity();
        const interval = setInterval(fetchActivity, DELAY_MS);
        return () => clearInterval(interval);
    }, [requestUrl]);

    const classes = useStyles();

    return (
        <div>
            {isRefreshing || activity === null ? (
                <List disablePadding className={classes.root}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.activityAvatar}>
                                <Skeleton variant="circle" animation="wave" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton animation="wave" height={24} width="90%" />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.activityAvatar}>
                                <Skeleton variant="circle" animation="wave" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton animation="wave" height={24} width="90%" />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.activityAvatar}>
                                <Skeleton variant="circle" animation="wave" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton animation="wave" height={24} width="90%" />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.activityAvatar}>
                                <Skeleton variant="circle" animation="wave" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton animation="wave" height={24} width="90%" />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.activityAvatar}>
                                <Skeleton variant="circle" animation="wave" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton animation="wave" height={24} width="90%" />
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.activityAvatar}>
                                <Skeleton variant="circle" animation="wave" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton animation="wave" height={24} width="90%" />
                        </ListItemText>
                    </ListItem>
                </List>
            ) : (
                <List disablePadding className={classes.root}>
                    {activity.slice(0, 30).map((activityEvent, index) => (
                        <ListItem key={`${activityEvent[1]}-${index}`}>
                            <ListItemAvatar>
                                <Avatar className={classes.activityAvatar}>
                                    <EqualizerIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemAvatar>
                                <Avatar className={classes.activityAvatar}>
                                    <img className={classes.gameIcon} src={getGameIcon(activityEvent[3])} alt="" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <>
                                        <Typography style={{ display: 'inline-block' }}>{activityEvent[4]}</Typography>
                                        <Typography style={{ display: 'inline-block' }} variant="caption" className={classes.dateLabel}>
                                            &nbsp;&nbsp;&bull;&nbsp;&nbsp;on{' '}
                                            {new Date(activityEvent[2] * 1000).toLocaleString('en-US', options)}
                                        </Typography>
                                    </>
                                }
                                secondary={activityEvent[5]}
                            />
                            <IconButton href={activityEvent[6]} target="_blank">
                                <OpenInNewIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            )}
        </div>
    );
}
